import "./Header.css";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import { Authentication } from "../types";
import { URLS } from "../utils/constants";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ApolloError, useQuery } from "@apollo/client";
import OrderListModal from "./order/OrderList";
import { Order, OrderResult, ORDERS } from "../apollo/queries/order";
import { DEFAULT_PAGE_INFO, PageInfo } from "../apollo/queries/pageinfo";
import { DEFAULT_PAGE_LIMIT } from "./PaginationNavigator";
import { UserResult, USER_GET_ME_QUERY } from "../apollo/queries/user";
import { LOGO_URL } from "./Logo";

type HeaderProperties = {
    onError: (error: string | ApolloError | JSX.Element) => void;
}

type NavBarProperties = {
    show: boolean;
    profileImageUrl?: string;
    onError?: (error: string | ApolloError | JSX.Element) => void;
}

function ProfileButtons({ profileImageUrl, show, onError }: NavBarProperties): React.ReactElement {
    const [showOrders, setShowOrders] = useState(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [pageInfo, setPageInfo] = useState<PageInfo>(DEFAULT_PAGE_INFO);
    const navigate = useNavigate();
    const [loggedIn, owner] = [Authentication.load().hasToken, Authentication.load().userId];
    const [modalTitle, setModalTitle] = useState("");
    const [completed, setCompleted] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [limit, setLimit] = useState(DEFAULT_PAGE_LIMIT);

    const { loading, fetchMore, refetch } = useQuery<OrderResult>(ORDERS, {
        variables: {
            owner,
            completed,
            canceled,
            first: limit,
        },
        skip: !loggedIn || !showOrders,
        onCompleted: ({ orders }) => {
            setOrders(orders.edges.map(edge => edge.node));
            setPageInfo(orders.pageInfo);
        },
        onError,
    });

    useEffect(() => {
        if (showOrders) {
            refetch().then(({ data: { orders } }) => {
                setOrders(orders.edges.map(edge => edge.node));
                setPageInfo(orders.pageInfo);
            });
        }
    }, [showOrders, refetch])

    if (show) {
        return (<>
            <OrderListModal
                title={modalTitle}
                show={showOrders}
                loading={loading}
                orders={orders}
                pageInfo={pageInfo}
                onClose={() => setShowOrders(false)}
                onSelect={order => {
                    setShowOrders(false);
                    navigate({
                        pathname: URLS.ORDER_EDIT,
                        search: createSearchParams({ orderId: order.slug }).toString(),
                    });
                }}
                onPreviousPage={() => fetchMore({
                    variables: {
                        first: undefined,
                        last: limit,
                        before: pageInfo.startCursor,
                    }
                }).then(({ data: { orders } }) => {
                    setOrders(orders.edges.map(edge => edge.node));
                    setPageInfo({
                        ...(orders.pageInfo || pageInfo),
                        hasNextPage: true,
                    });
                })}
                onNextPage={() => fetchMore({
                    variables: {
                        after: pageInfo.endCursor,
                    }
                }).then(({ data: { orders } }) => {
                    setOrders(orders.edges.map(edge => edge.node));
                    setPageInfo({
                        ...(orders.pageInfo || pageInfo),
                        hasPreviousPage: true,
                    });
                })}
            />
            <NavDropdown
                id="nav-dropdown"
                title={<strong>My Profile</strong>}
            >
                <NavDropdown.Item className="d-flex justify-content-between" onClick={() => navigate(URLS.USER_PROFILE)}>
                    My Info
                    {
                        profileImageUrl &&
                        <img
                            src={profileImageUrl}
                            className="mini-profile"
                            alt="Profile"
                        />
                    }
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(URLS.ORDER_EDIT)}>
                    Create a Listing
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => {
                    setModalTitle("My Active Listings");
                    setCompleted(false);
                    setCanceled(false);
                    setShowOrders(true);
                }}>
                    My Active Listings
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => {
                    setModalTitle("My Completed Listings");
                    setCompleted(true);
                    setCanceled(false);
                    setShowOrders(true);
                }}>
                    My Completed Listings
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => {
                    setModalTitle("My Canceled Listings");
                    setCompleted(false);
                    setCanceled(true);
                    setShowOrders(true);
                }}>
                    My Canceled Listings
                </NavDropdown.Item>
            </NavDropdown>
        </>);
    }
    return (<></>);
}

function LogInOutButtons(props: NavBarProperties): React.ReactElement {
    const navigate = useNavigate();

    if (props.show) {
        return (
            <Nav.Link onClick={
                () => {
                    Authentication.clear();
                    navigate(URLS.LANDING);
                }}
            >
                Logout
            </Nav.Link>
        );
    }

    return (
        <>
            <Nav.Link
                onClick={() => navigate({
                    pathname: URLS.USER_LOGIN,
                    search: createSearchParams({
                        from: window.location.toString()
                    }).toString(),
                })}
            >
                <strong>Login</strong>
            </Nav.Link>
            <Nav.Link
                className="btn custom-nav-link-button"
                onClick={() => navigate(URLS.USER_SIGNUP)}
            >
                <strong>Sign Up</strong>
            </Nav.Link>
        </>
    );
}

export default function Header({ onError }: HeaderProperties) {
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(LOGO_URL);
    const loggedIn = Authentication.load().hasToken;

    useQuery<UserResult>(USER_GET_ME_QUERY, {
        skip: !loggedIn,
        onCompleted: ({ me }) => setImageUrl(me.imageUrl || LOGO_URL),
        onError,
    });

    return (
        <>
            <Navbar expand="md" bg="light" variant="light">
                <Container>
                    <Navbar.Brand onClick={() => navigate(URLS.LANDING)}>
                        <span className="d-inline-block btn company">
                            <strong>TELAPORT</strong>
                        </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-nav" />
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="ms-auto">
                            <ProfileButtons profileImageUrl={imageUrl} show={loggedIn} onError={onError} />
                            {loggedIn && (
                                <Nav.Link
                                    onClick={() =>
                                        navigate({
                                            pathname: URLS.STORE,
                                            search: createSearchParams({
                                                owner: Authentication.load().userId,
                                            }).toString(),
                                        })
                                    }
                                >
                                    <strong>My Store</strong>
                                </Nav.Link>
                            )}
                            <Nav.Link onClick={() => navigate({ pathname: URLS.STORE })}>
                                <strong>EXPLORE</strong>
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate(URLS.ABOUT)}>
                                <strong>About Us</strong>
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate(URLS.CONTACT)}>
                                <strong>Contact Us</strong>
                            </Nav.Link>
                            <LogInOutButtons show={loggedIn} />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Create a Listing Button Below Navbar */}
            <div className="text-center mb-4">
                <Button
                    variant="primary"
                    onClick={() => navigate(URLS.ORDER_EDIT)} // Navigate to Create a Listing
                >
                    Create Post
                </Button>
            </div>
        </>
    );
}
